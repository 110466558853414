import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Fade from 'react-reveal/Fade' // see https://www.react-reveal.com/examples/

import Layout from '../components/Layout'

const Wrapper = styled.div`
background-color:black;
font-family: 'PT Sans Caption', sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
  text-align: center;
  padding: 1rem;
  animation: hide;
  
`

const Header = styled.h1`
margin-top: 0;
  color: #ffff;
`

const Subheader = styled.h2`
color: #ffff;
margin:0;
  font-size: 2rem;
`

const Text = styled.p`
color: #ffff;
`

const Button = styled.button.attrs({
  target: '_blank'
})`
`
const Logo2 = styled.img.attrs({
  alt: 'logo2'
})`
  max-width: 100%;
  max-height: 100%;
`

const Iframe = styled.iframe`
 width:100%;
`

const SimpleStarter = ({ data: { markdownRemark: { frontmatter } } }) =>
  <Layout frontmatter={{...frontmatter, backgroundColor: '#000'}}>
    <Wrapper>
    <Fade bottom ssrReveal>
      <div>
      <Header><Logo2 src={frontmatter.logo2.publicURL} /></Header>
        <Subheader>
          <Iframe 
            src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FPapaLeeChineseRestaurant%2Fvideos%2F824267131091115%2F&width=500&show_text=false&appId=115439919016780&height=280" 
            height="350" 
            width="550" 
            allowFullscreen="false" 
            frameBorder="0">
          </Iframe>
        </Subheader>
        <Text>{frontmatter.text}</Text>
        <Button href={frontmatter.link}>{frontmatter.buttonText}</Button>
      </div>
    </Fade>
    </Wrapper>
  </Layout>

export default SimpleStarter

export const pageQuery = graphql`
  query SimpleStarterBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        templateKey
        title
        description
        header
        subheader
        text
        buttonText
        link
        logo2 {
          publicURL
        }
        favicon {
          publicURL
        }
      }
    }
  }
`
